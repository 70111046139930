@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";


body {
  @apply text-xs text-gray-600;
}

* {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginform {
  @apply flex flex-col items-center border border-solid px-4 py-2 bg-white w-[400px] rounded-xl;
}

.sidebar .active {
  border-right: 3px solid #4b5563;
  @apply bg-orange-500 text-white;
}

.bubble {
  @apply bg-white text-xs text-gray-600 rounded-xl px-2 py-1;
}

.blue-button {
  @apply bg-blue-900 text-white text-xs px-2 py-1 disabled:bg-opacity-50 w-full md:w-fit;
}

.orange-button {
  @apply bg-leechiuOrange text-white text-xs px-2 py-1 disabled:bg-opacity-50 w-full md:w-fit;
}

.small-form-button {
  @apply bg-blue-900 text-white text-xs px-2 py-1 disabled:bg-opacity-50 w-full;
}

.close-button {
  @apply absolute top-1 right-1 underline text-xs text-gray-600 cursor-pointer;
}

.header {
  @apply uppercase  text-leechiuBlue font-bold;
}

.small-form {
  @apply min-w-[350px] min-h-[500px];
}

.pending {
  @apply bg-yellow-200 text-yellow-800;
}

.approved {
  @apply bg-green-600 text-white;
}

.rejected {
  @apply bg-red-200 text-red-800;
}

.active-pill {
  @apply px-2 py-1 rounded-full text-xs bg-leechiuOrange text-white border border-leechiuOrange w-fit cursor-pointer;
}

.inactive-pill {
  @apply px-2 py-1 rounded-full text-xs bg-gray-100 text-leechiuOrange border border-leechiuOrange w-fit cursor-pointer;
}

.links-container {
  @apply flex flex-row items-center gap-4 bg-gray-200 rounded-lg p-2 w-full lg:w-fit;
}

.activeLinkStyle {
  @apply bg-white p-2 rounded-lg font-bold text-leechiuBlue shadow-lg cursor-pointer;
}

.inactiveLinkStyle {
  @apply p-2 rounded-lg font-bold text-gray-600 cursor-pointer;
}

.card {
  @apply bg-white shadow-sm rounded-md p-2 cursor-pointer hover:bg-gray-200;
}

.pending {
  @apply bg-orange-200 rounded-full p-1 px-2 text-gray-600 text-center font-bold;
}

.returned {
  @apply bg-red-200 rounded-full p-1 px-2 text-gray-600 text-center font-bold;
}

.approved {
  @apply bg-green-200 rounded-full p-1 px-2 text-gray-600 text-center font-bold;
}

.rejected {
  @apply bg-red-200 rounded-full p-1 px-2 text-gray-600 text-center font-bold;
}

.page {
  @apply bg-white p-4 rounded-lg text-gray-600 text-left w-full min-h-screen relative;
}

.comment {
  @apply bg-gray-100 p-2 rounded-lg text-gray-600;
}

.label {
  @apply text-gray-800 font-semibold;
}

.published {
  @apply bg-green-500 text-white px-2 py-1 rounded-lg w-fit;
}

.unpublished {
  @apply bg-gray-600 text-white px-2 py-1 rounded-lg w-fit;
}

.small-page {
  @apply bg-white p-4 rounded-lg text-gray-600 text-left min-w-96 min-h-[500px] relative;
}


